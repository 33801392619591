<template>
  <v-app>
    <v-app-bar app color="base" dark flat>
      <div class="d-flex align-center">
        hhh<img src="https://rampdefi.com/icons/ramp_v4.svg" class="logo" />
      </div>

      <v-spacer></v-spacer>
    </v-app-bar>

    <v-main>
      <router-view class="base" />
      <snack-bar />
    </v-main>
  </v-app>
</template>

<script>
import snackBar from "./components/snackBar/snackBar.vue";
export default {
  components: { snackBar },
  name: "App",

  data: () => ({
    //
  }),
  beforeCreate() {
    document.title = "Rampdefi";
  },
};
</script>
<style scoped>
@import url("./assets/style.css");
.logo {
  width: 40%;
}
@media all and (max-width: 600px) {
  .logo {
    width: 30%;
  }
}
</style>
