<template>
  <v-row justify="center">
    <v-dialog v-model="getShowAnimation.show" persistent max-width="400px">
      <v-card class="rounded-xl animation-card" elevation="0">
        <div class="d-flex">
          <v-spacer></v-spacer
          ><v-btn icon @click="showAnimation({ show: false, id: '', type: '' })"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </div>
        <div>
          <lottie :options="defaultOptions" :max-height="200" :max-width="350" />
        </div>
        <div class="text-center font-weight-bold primary--text">Your Order Has been Registered, will process your order within 24-72 Hrs</div>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import Lottie from "vue-lottie";
import { mapActions, mapGetters } from "vuex";
import * as animationData from "@/assets/animation/success.json";
export default {
  components: {
    lottie: Lottie,
  },
  data() {
    return {
      defaultOptions: { animationData: animationData },
      animationSpeed: 1,
    };
  },
  computed: {
    ...mapGetters({
      getShowAnimation: "animation/getShowAnimation",
    }),
    show: {
      get() {
        if (this.getShowAnimation.show) return this.getShowAnimation.show;
        else return null;
      },
      set(value) {
        this.showAnimation({ show: value });
      },
    },
  },
  watch: {
    show(value) {
      if (value) {
        this.openModal();
      } else this.closeModal();
    },
  },
  methods: {
    ...mapActions({
      showAnimation: "animation/showAnimation",
    }),
    openModel() {},
    closeModel() {},
  },
};
</script>
<style scoped>
.animation-card{
    padding: 5% 3%;
}
</style>
