export default {
  required: (value) => !!value || "This is Required.",
  email: (v) => {
    /* eslint-disable-next-line no-useless-escape*/
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (re.test(String(v).toLowerCase().trim())) {
      return true;
    }
    return "Invalid Email";
  },
  phone: (v) => {
    /* eslint-disable-next-line no-useless-escape*/
    const re = /^(\+91)?[0]?(91)?[6789]\d{9}$/;
    if (v < 0) return "Mobile number can't be negative";
    if (v?.length != 10) return "Number should be 10 digits only";
    if (re.test(v)) {
      return true;
    }
    return "Invalid Phone Number";
  },
  amount: (v) => {
    if (v < 100) return "Amount Must be greater than 100";
    else return true;
  },
  blockInvalidChar: (e) =>
    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault(),
};
