<template>
  <div class="align-center justify-center">
    <v-container fluid class="content-wrapper">
      <v-row class="d-flex align-center justify-center"> 
        <v-col cols="12" sm="12" md="6" xl="6">
          <div class="">
            <span class="hero-section-title black--text"
              >The multi-chain DeFi lending platform that maximizes capital
              efficiency on your assets</span
            >
          </div>
          <div class="d-block d-sm-flex align-center mt-5">
            <v-btn
              @click="openDocumentation()"
              x-large
              class="primary--text mr-5 text-capitalize font-weight-bold rounded-0 btns"
              outlined
            >
              Documentation</v-btn
            >
            <v-btn
              @click="openLaunchApp()"
              x-large
              class="secondary primary--text font-weight-bold text-capitalize rounded-0 btns"
            >
              Launch App</v-btn
            >
          </div>
        </v-col>
        <v-col cols="12" sm="12" md="6" xl="6">
          <div class="align-center justify-center">
            <iframe
              class="video"
              src="https://www.youtube.com/embed/4MpYmENp3HM?si=aD4xEW7twoq90D9z"
            >
            </iframe>
            <div class="accent--text text--darken-2 text-center">
              How Ramp defi works
            </div>
          </div>
        </v-col></v-row
      >
    </v-container>
  </div>
</template>
<script>
export default {
  methods: {
    openDocumentation() {
      window.open("https://docs.rampdefi.com/ramp-defi/");
    },
    openLaunchApp() {
      window.open("https://appv2.rampdefi.com/#/lever");
    },
  },
};
</script>

<style>
.content-wrapper {
  padding: 5%;
}
.hero-section-title {
  font-weight: 600;
  font-size: 35px;
}
.video {
  width: 100%;
  height: 320px;
}
/* .btns{
  font-size: 40px;
} */
@media all and (max-width: 800px) {
  .video {
    width: 100%;
    height: 320px;
  }
}
@media all and (max-width: 600px) {
  .content-wrapper {
    padding: 2%;
  }
  .hero-section-title {
    font-weight: 600;
    font-size: 20px;
    text-align: justify;
  }
  .video {
    width: 100%;
    height: 220px;
  }
  .btns {
    width: 100%;
    margin-bottom: 5px;
  }
}
</style>
