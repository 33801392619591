import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import Axios from "@/api/BaseAxios";
import urls from "./common/apiUrl/index";
import VueMarqueeSlider from 'vue-marquee-slider';

Vue.config.productionTip = false;
Vue.prototype.$Axios = Axios;
Vue.prototype.$api_urls = urls;
Vue.use(VueMarqueeSlider);



new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");


