<template>
  <div class="feature-div background_accent">
    <v-row>
      <v-col
        cols="12"
        sm="4"
        v-for="(data, index) in feature_data"
        :key="index"
      >
        <v-card class="background_accent" elevation="0">
          <div class="d-flex align-center justify-center">
          <img :src="data.img" class="rounded-circle feature-img" />
          </div>
          <div class="font-weight-medium text-center feature-title">{{ data.title }}</div>
          <div class="accent--text text--darken-2 py-2 text-center feature-description">
            {{ data.text }}
          </div>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  data() {
    return {
      feature_data: [
        {
          title: "Earn yields while you borrow",
          text: "Continue to earn yields on deposited assets while you borrow rUSD stablecoin",
          img: "https://rampdefi.com/static/4b1d94069ceb06490a679718564f21e5/38cd0/earn.png",
        },
        {
          title: "Borrow rUSD for additional liquidity",
          text: "Mint stablecoin against your deposited assets and pursue new investment opportunities.",
          img: "https://rampdefi.com/static/f0f6823ffccd1230fd74e2230a5023e6/38cd0/borrow.png",
        },
        {
          title: "Cross-chain rUSD transfers",
          text: "Move stable coin seamlessly across multiple blockchain networks.",
          img: "https://rampdefi.com/static/8a79200ec71ac774290a749cae640400/38cd0/crossChain.png",
        },
      ],
    };
  },
};
</script>
<style scoped>
.feature-div {
  padding: 5% 10%;
}
.feature-img{
  margin: 20px 0;
  width: 200px;
}
@media all and (max-width: 800px) {
.feature-div {
  padding: 5%;
}
.feature-img{
  width: 150px;
}
.feature-title{
  font-size: 15px;
}
.feature-description{
  font-size: 14px;
}
}
</style>
