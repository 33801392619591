<template>
  <div class="register-div primary">
    <ul class="circles">
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
    </ul>

    <v-row class="d-flex align-center justify-center">
      <v-col cols="12" sm="6">
        <p class="white--text font-weight-bold about-head">About RAMP DEFI</p>
        <div class="text-justify secondary--text text--lighten-5 about">
          RAMP DeFi is a multi-chain DeFi protocol that helps asset owners
          achieve capital efficiency by offering a powerful and comprehensive
          solution to multi-task users’ crypto-assets, maximizing the value and
          returns. Users can earn optimal high-yield returns from their
          deposited assets, collateralized stablecoin against them at the same
          time to get extra liquidity to pursue other investment opportunities,
          with great accessibility across multiple blockchain networks.
        </div>
        <div class="d-flex align-center py-10 btn-div">
          <v-btn
            x-large
            @click="learnMore()"
            class="secondary primary--text rounded-0 font-weight-bold text-capitalize btns"
            >Learn More</v-btn
          >
        </div>
      </v-col>
      <v-col cols="12" sm="6">
        <div>
          <vue-marquee-slider id="marquee-slider" :speed="12000" :width="600">
            <div class="white--text">
              ** We recommend not to make multiple transaction in a day **
            </div>
            <div class="white--text">
              ** No plateform fee will applied on transaction above ₮500 **
            </div>
          </vue-marquee-slider>
        </div>
        <div class="d-flex align-center justify-center">
          <v-card
            elevation="22"
            class="rounded-xxl pa-0"
            :max-width="card_width"
          >
            <v-card-text>
              <v-stepper v-model="stepper_id" elevation="0">
                <v-stepper-items>
                  <v-stepper-content step="1" class="stepperContent">
                    <div class="font-weight-bold form-title text-center pb-7">
                      BUY RAMP TOKENS
                    </div>
                    <v-form lazy-validation ref="register_form">
                      <v-row no-gutters>
                        <v-col cols="12"
                          ><v-text-field
                            outlined
                            dense
                            label="Email"
                            type="email"
                            v-model="email"
                            :rules="[rules.required, rules.email]"
                          ></v-text-field
                        ></v-col>
                        <v-col cols="12"
                          ><v-text-field
                            outlined
                            type="number"
                            hide-spin-buttons
                            dense
                            counter="10"
                            label="Phone No."
                            v-model="phone"
                            :rules="[rules.required, rules.phone]"
                            @keydown="rules.blockInvalidChar"
                            onkeypress="if(this.value.length==10) return false;"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12"
                          ><v-text-field
                            outlined
                            dense
                            v-model="wallet_address"
                            label="RAMP Wallet Address"
                            :rules="[rules.required]"
                          ></v-text-field
                        ></v-col>
                        <v-col cols="12">
                          <div>Select Payment Option</div>
                          <v-radio-group v-model="payment" mandatory>
                            <v-radio label="USDT(BEP20)" value="USDT"></v-radio>
                            <v-radio label="BTC(BEP20)" value="BTC"></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                      <div class="d-flex align-center py-2">
                        <v-btn
                          @click="goToStep2()"
                          block
                          class="primary white-text py-2 px-3"
                          >Next</v-btn
                        >
                      </div>
                    </v-form>
                  </v-stepper-content>
                  <v-stepper-content step="2" class="card2">
                    <div class="d-flex mb-7 align-center">
                      <v-btn icon @click="stepper_id = '1'"
                        ><v-icon class="primary--text"
                          >mdi-chevron-left</v-icon
                        ></v-btn
                      >
                      <div class="form-title text-center font-weight-bold">
                        RAMP Calculator and Payment
                      </div>
                    </div>

                    <v-form lazy-validation ref="payment_form">
                      <v-row no-gutters>
                        <v-col cols="12">
                          <v-text-field
                            outlined
                            label="Enter Amount"
                            placeholder="Min 100"
                            :rules="[rules.required, rules.amount]"
                            dense
                            type="number"
                            hide-spin-buttons
                            prefix="₮"
                            v-model="amount"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12"
                          ><span class="font-weight-bold"
                            >Current Price :
                          </span>
                          ₮ {{ currentPrice }}</v-col
                        >
                        <v-col cols="12" v-if="amount >= 100" class="mb-4">
                          <div>
                            <span class="font-weight-bold"
                              >Total RAMP Token :</span
                            >
                            {{ formattedRampToken }}
                          </div>
                          <div>
                            <span class="font-weight-bold">Platform Fee :</span>
                            ₮ {{ formattedPlatFormFee }}
                          </div>
                          <div>
                            <span class="font-weight-bold"
                              >Total Amount Payable :</span
                            >
                            ₮ {{ totalPayableAmount }}
                          </div>
                        </v-col>
                      </v-row>
                    </v-form>
                    <div v-if="amount >= 100">
                      <h3 class="text-center">Scan QR Code to pay</h3>

                      <div class="d-flex align-center justify-space-around">
                        <img
                          src="@/assets/usdt_code.jpeg"
                          class="mx-auto qr-img"
                          v-if="payment == 'USDT'"
                        />
                        <!-- <caption v-if="payment == 'USDT'">
                        Scan for USDT(BEP20) payment
                      </caption> -->

                        <img
                          src="@/assets/btc_code.jpeg"
                          class="mx-auto qr-img"
                          v-if="payment == 'BTC'"
                        />
                        <!-- <caption v-if="payment == 'BTC'">
                        Scan for BTC(BEP20) payment
                      </caption> -->
                      </div>

                      <div class="text-center">OR</div>
                      <div v-if="payment == 'USDT'" class="text-center">
                        <a
                          href="https://link.trustwallet.com/send?asset=c20000714_t0x55d398326f99059fF775485246999027B3197955&address=0x5B823a173423417261318101Aa1773606A215Fb8"
                          target="_blank"
                          >Pay Via Trust Wallet</a
                        >
                      </div>
                      <div v-if="payment == 'BTC'" class="text-center">
                        <a
                          href=" https://link.trustwallet.com/send?address=0x5B823a173423417261318101Aa1773606A215Fb8&asset=c20000714_t0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c"
                          target="_blank"
                          >Pay Via Trust Wallet</a
                        >
                      </div>
                    </div>
                    <div class="d-flex align-center py-2">
                      <v-btn
                        @click="goToStep3()"
                        block
                        class="primary white-text py-2 px-3 btns"
                        >Next</v-btn
                      >
                    </div>
                  </v-stepper-content>
                  <v-stepper-content step="3" class="card2">
                    <div class="d-flex mb-5 align-center">
                      <v-btn icon @click="stepper_id = '2'"
                        ><v-icon class="primary--text"
                          >mdi-chevron-left</v-icon
                        ></v-btn
                      >
                      <div class="form-title text-center font-weight-bold">
                        Attach your Payment photo
                      </div>
                    </div>

                    <v-form>
                      <v-row no-gutters>
                        <v-col cols="12" class="py-1" style="max-height: 195px">
                          <input
                            type="file"
                            ref="uploadImage"
                            class="d-none"
                            @change="imageUpload"
                            accept="image/*"
                          />
                          <div
                            class="logo-div mx-auto align-center text-center pointer mb-3"
                            @click="$refs.uploadImage.click()"
                          >
                            <span v-if="!previewFile">
                              <v-img
                                v-if="profileLoader"
                                min-height="135px"
                                max-height="135px"
                              ></v-img>
                              <span v-else>
                                <v-icon class="mt-6" size="40"
                                  >mdi-cloud-upload-outline</v-icon
                                >
                                <div class="text-caption font-weight-black">
                                  Upload Image
                                </div>
                                <div class="text-caption mb-n2">
                                  click to browse image
                                </div>
                                <span style="font-size: 11px"
                                  >(png/jpeg/jpg)</span
                                >
                              </span>
                            </span>
                            <v-img
                              v-else
                              min-height="135px"
                              max-height="135px"
                              class="pointer"
                              style="
                                object-fit: cover;
                                border-radius: 5px;
                                margin: 1px;
                              "
                              :src="previewFile"
                            ></v-img>
                          </div>
                        </v-col>
                      </v-row>
                      <div class="d-block align-center py-2">
                        <v-btn
                          block
                          @click="submit()"
                          :loading="submitLoader"
                          class="primary white-text py-2 px-3"
                          >Submit</v-btn
                        >
                      </div>
                    </v-form>
                  </v-stepper-content>
                </v-stepper-items>
              </v-stepper>
            </v-card-text>
          </v-card>
        </div>
      </v-col>
    </v-row>
    <!-- <v-btn @click="showAnimation({ show: true })">ehhe</v-btn> -->
    <success-dialog />
  </div>
</template>
<script>
import successDialog from "@/components/successDialog.vue";
// import VueMarqueeSlider from "vue-marquee-slider";
import RULES from "@/common/fieldRules";
import { mapActions } from "vuex";
export default {
  components: { successDialog },
  data() {
    return {
      rules: RULES,
      profileLoader: false,
      payment_image: "",
      previewFile: "",
      stepper_id: 1,
      wallet_address: "",
      phone: null,
      email: "",
      payment: "",
      amount: null,
      submitLoader: false,
      formattedRampToken: null,
      formattedPlatFormFee: null,
      totalPayableAmount: null,
      currentPrice: null,
    };
  },

  watch: {
    amount() {
      const percentage = 5;
      const baseValue = this.amount;

      const platFormFee = (percentage / 100) * baseValue;
      if (this.amount < 500) {
        this.formattedPlatFormFee = platFormFee.toFixed(2);
      } else this.formattedPlatFormFee = 0;
      // this.formattedPlatFormFee = platFormFee.toFixed(2);
      const totalSXPToken = this.amount / this.currentPrice;
      this.formattedRampToken = totalSXPToken.toFixed(3);
      this.totalPayableAmount =
        Number(this.amount) + Number(this.formattedPlatFormFee);
    },
  },
  computed: {
    card_width() {
      if (this.stepper_id == 3) {
        return "400";
      } else return "600";
    },
  },
  mounted() {
    setInterval(this.generateRandomNumber, 60000);

    this.generateRandomNumber();
  },

  methods: {
    ...mapActions({
      showSnackbar: "snackBar/showSnackbar",
      showAnimation: "animation/showAnimation",
    }),
    learnMore() {
      window.open("https://docs.rampdefi.com/ramp-defi/");
    },
    imageUpload() {
      let input = this.$refs.uploadImage;
      let file = input.files;

      this.payment_image = file[0];
      if (file && file[0]) {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.previewFile = e.target.result;
        };
        reader.readAsDataURL(file[0]);
      }
    },

    generateRandomNumber() {
      const min = 0.045;
      const max = 0.046;
      this.currentPrice = (Math.random() * (max - min) + min).toFixed(6);
    },
    goToStep2() {
      if (this.$refs.register_form.validate()) {
        this.stepper_id = 2;
      }
    },
    goToStep3() {
      if (this.$refs.payment_form.validate()) {
        this.stepper_id = 3;
      }
    },
    submit() {
      let result_description =
        "Email :" +
        this.email +
        "\n" +
        "Mobile No. : " +
        this.phone +
        "\n" +
        "Wallet Address: " +
        this.wallet_address +
        "\n" +
        "Total Token : " +
        this.formattedRampToken +
        "\n" +
        "Current Price : " +
        this.currentPrice +
        "\n" +
        "Amount : " +
        this.amount +
        "\n" +
        "Platform Fee : " +
        this.formattedPlatFormFee +
        "\n" +
        "Total Payable Amount : " +
        this.totalPayableAmount;

      if (this.payment_image) {
        this.submitLoader = true;
        const successHandler = () => {
          this.showAnimation({ show: true });
          this.submitLoader = false;
          this.$refs.register_form.reset();
          this.$refs.payment_form.reset();
          this.$refs.uploadImage.value = null;
          this.payment_image = "";
          this.previewFile = "";
          this.wallet_address = "";
          this.phone = null;
          this.email = "";
          this.payment = "";
          this.formattedRampToken = null;
          this.formattedPlatFormFee = null;
          this.totalPayableAmount = null;
          this.currentPrice = null;
          this.stepper_id = 1;
        };
        const failureHandler = () => {
          this.submitLoader = false;
        };
        let formData = new FormData();
        formData.append("to", this.email);
        formData.append("text", result_description);
        formData.append("image", this.payment_image);
        formData.append("subject", "Order Info");
        return this.$Axios.request_POST(
          this.$api_urls.uploadRampDetail,
          formData,
          {},
          null,
          successHandler,
          failureHandler,
          false
        );
      } else {
        this.showSnackbar({
          text: "Please upload your Payment photo",
          color: "error",
        });
      }
    },
  },
};
</script>

<style scoped>
.register-div {
  padding: 5% 10%;
  position: relative;
}
.circles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.circles li {
  position: absolute;
  display: block;
  list-style: none;
  width: 20px;
  height: 20px;
  background: rgba(255, 255, 255, 0.2);
  animation: animate 25s linear infinite;
  bottom: -150px;
}

.circles li:nth-child(1) {
  left: 25%;
  width: 80px;
  height: 80px;
  animation-delay: 0s;
}

.circles li:nth-child(2) {
  left: 10%;
  width: 60px;
  height: 60px;
  animation-delay: 2s;
  animation-duration: 12s;
}

.circles li:nth-child(3) {
  left: 70%;
  width: 20px;
  height: 20px;
  animation-delay: 4s;
}

.circles li:nth-child(4) {
  left: 40%;
  width: 60px;
  height: 60px;
  animation-delay: 0s;
  animation-duration: 18s;
}

.circles li:nth-child(5) {
  left: 65%;
  width: 50px;
  height: 50px;
  animation-delay: 0s;
}

.circles li:nth-child(6) {
  left: 75%;
  width: 110px;
  height: 110px;
  animation-delay: 3s;
}

.circles li:nth-child(7) {
  left: 35%;
  width: 150px;
  height: 150px;
  animation-delay: 7s;
}

.circles li:nth-child(8) {
  left: 50%;
  width: 25px;
  height: 25px;
  animation-delay: 15s;
  animation-duration: 45s;
}

.circles li:nth-child(9) {
  left: 20%;
  width: 15px;
  height: 15px;
  animation-delay: 2s;
  animation-duration: 35s;
}

.circles li:nth-child(10) {
  left: 85%;
  width: 150px;
  height: 150px;
  animation-delay: 0s;
  animation-duration: 11s;
}
.circles li:nth-child(11) {
  left: 86%;
  width: 60px;
  height: 60px;
  animation-delay: 1s;
  animation-duration: 40s;
}

@keyframes animate {
  0% {
    transform: translateY(0) rotate(0deg);
    opacity: 1;
    border-radius: 0;
  }

  100% {
    transform: translateY(-1000px) rotate(720deg);
    opacity: 0;
    border-radius: 50%;
  }
}
.card2 {
  padding: 0 5px !important;
}
.logo-div {
  height: 140px;
  width: 140px;
  border-radius: 5px;
  border: 1px dashed #11264d;
}
.stepperContent {
  padding: 20px 10px;
}
.form-title {
  font-size: 20px;
}
.about-head {
  font-size: 30px;
}
.qr-img {
  width: 40%;
  margin: 2% 0;
}

@media all and (max-width: 800px) {
  .register-div {
    padding: 5%;
  }
}
@media all and (max-width: 600px) {
  .register-div {
    padding: 10% 5%;
  }

  .about-head {
    font-size: 25px;
    text-align: center;
  }
  .about {
    font-size: 14px;
  }
  .btn-div {
    padding: 2px 0 !important;
  }
  .btns {
    width: 100%;
    display: block;
  }
  .stepperContent {
    padding: 5px;
  }
  .card2 {
    padding: 0 !important;
  }
}
</style>
