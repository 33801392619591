import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: "#11264D",
                base:"#FFFFFF",
                secondary: "#00B5D1",
                accent: '#D3D3D3',
                background_accent: '#F3F4F6',
              
            }
        }
    }
});
