<template>
  <div>
    <section>
      <hero-section />
    </section>
    <section>
      <feature-section />
    </section>
    <section>
      <ecosystem-section />
    </section>
    <section>
      <register-user />
    </section>

    <!-- <section class="primary about-section">
      <p class="text-center white--text font-weight-bold about-head">
        About RAMP DEFI
      </p>
      <div class="text-center secondary--text text--lighten-5">
        RAMP DeFi is a multi-chain DeFi protocol that helps asset owners achieve
        capital efficiency by offering a powerful and comprehensive solution to
        multi-task users’ crypto-assets, maximizing the value and returns. Users
        can earn optimal high-yield returns from their deposited assets,
        collateralized stablecoin against them at the same time to get extra
        liquidity to pursue other investment opportunities, with great
        accessibility across multiple blockchain networks.
      </div>
      <div class="d-flex align-center justify-center py-10">
        <v-btn
          class="secondary primary--text px-10 py-7 rounded-0 font-weight-bold text-capitalize"
          >Learn More</v-btn
        >
      </div>
    </section> -->
    <section
      class="subscribe-section background_accent d-flex align-center justify-center"
    >
      <div class="">
        <div class="text-center font-weight-bold subscribe-section-title">
          RAMP DEFI Ecosystem
        </div>
        <div class="accent--text text--darken-2 text-center">
          Stay informed with our latest news and development
        </div>
        <div class="d-sm-flex d-block mt-5 align-center justify-space-between">
          <v-text-field
            outlined
            placeholder="Email Address"
            hide-details
          ></v-text-field>
          <v-btn
            x-large
            class="secondary primary--text mx-2 subscribe font-weight-bold rounded-0 text-capitalize"
            >Subscribe</v-btn
          >
        </div>
      </div>
    </section>

    <section>
      <footer-section />
    </section>
  </div>
</template>

<script>
import heroSection from "@/components/heroSection.vue";
import featureSection from "@/components/featureSection.vue";
import ecosystemSection from "@/components/ecosystemSection.vue";
import registerUser from "@/components/registerUser.vue";
import footerSection from "@/components/footerSection.vue";
export default {
  name: "HomeView",
  components: {
    registerUser,
    footerSection,
    heroSection,
    featureSection,
    ecosystemSection,
  },
};
</script>
<style scoped>
.subscribe-section {
  padding: 80px 100px;
}
.subscribe-section-title {
  font-size: 40px;
}
@media all and (max-width: 600px) {
  .subscribe-section {
    padding: 20px 10px;
  }
  .subscribe-section-title {
    font-size: 25px;
  }
  .subscribe {
    width: 100%;
    margin-top: 10px;
    margin-left: 0 !important;
  }
}
</style>
