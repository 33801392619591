export default {
    namespaced: true,
    state: {
        showAnimation: {
        type: "",
        id: "",
        show: false,
      },
    },
    getters: {
        getShowAnimation: (state) => state.showAnimation,
    },
    mutations: {
      SHOW_ANIMATION(state, obj) {
        if (obj.show) {
          state.showAnimation.id = obj.id ? obj.id : null;
          state.showAnimation.type = obj.type ? obj.type : "";
        }
        state.showAnimation.show = obj.show;
      },
    },
    actions: {
      showAnimation({ commit }, obj) {
        commit("SHOW_ANIMATION", obj);
      },
    },
  };
  