import axios from "axios";
import store from "@/store";
// import authToken from "../common/authToken";
// import dayjs from "dayjs";
// import jwt_decode from "jwt-decode";
// import apiUrl from "../common/apiUrl/index.js";
// import router from "../router";

let CancelToken = axios.CancelToken;
let source = CancelToken.source();

axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
// axios.defaults.baseURL = "https://crypto-backend-8rk5.onrender.com/";
axios.defaults.baseURL = "https://rampdefi.onrender.com";
// axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;

// let isRefreshing = false;
// let requestQueue = [];
// const callRequestsFromQueue = (error, access_token) => {
//   requestQueue.forEach((req) => {
//     if (error) req.reject(error);
//     else {
//       req.resolve(access_token);
//     }
//   });
//   requestQueue = [];
// };
// axios.interceptors.request.use(
//   (req) => {
//     const { accessToken, refreshToken } = authToken.getToken();
//     if (accessToken && refreshToken) {
//       // const refresh = jwt_decode(refreshToken);
//       // const isRefreshExpired = dayjs.unix(refresh.exp).diff(dayjs()) < 1;
//       // if (isRefreshExpired) {
//       //     // store.state.app.isCancel = true;
//       //     authToken.removeToken();
//       //     router.push({ name: "login" });
//       //     return null;
//       // }
//       // return req;
//     } else if (store.state.app.isCancel) {
//       return null;
//     } else return req;
//   },
//   (error) => {
//     return Promise.reject(error);
//   }
// );
// axios.interceptors.response.use(
//   (response) => {
//     return response;
//   },
//   async (error) => {
//     const req = error.config;
//     if (error.response?.status === 401 && !req._retry) {
//       if (error.response.data.code == "token_not_valid") {
//         authToken.removeToken();
//         router.push({ name: "login" });
//         return null;
//       }

//       const refreshToken = localStorage.getItem("refreshToken");
//       if (isRefreshing) {
//         return new Promise(function (resolve, reject) {
//           requestQueue.push({ resolve, reject });
//         })
//           .then((access_token) => {
//             req.headers["authorization"] = "Bearer " + access_token;
//             console.log(req);
//             return axios(req);
//           })
//           .catch((error) => {
//             return Promise.reject(error);
//           });
//       }

//       req._retry = true;
//       isRefreshing = true;

//       return new Promise(function (resolve, reject) {
//         axios
//           .post(apiUrl.refresh__token, {
//             refresh: refreshToken,
//           })
//           .then((response) => {
//             const access_token = response.data.access;
//             const refresh_token = response.data.refresh;
//             localStorage.setItem("accessToken", access_token);
//             localStorage.setItem("refreshToken", refresh_token);
//             req.headers["authorization"] = "Bearer " + access_token;
//             callRequestsFromQueue(null, access_token);
//             // console.log("data", req);
//             resolve(axios(req));
//           })
//           .catch((error) => {
//             authToken.removeToken();
//             router.push({ name: "login" });
//             callRequestsFromQueue(error, null);
//             reject(error);
//           })
//           .finally(() => {
//             isRefreshing = false;
//           });
//       });
//     }
//     return Promise.reject(error);
//   }
// );

var showSnackbar = (snackbar) =>
  store.dispatch("snackBar/showSnackbar", snackbar);

export const AxiosHelper = {
  errorHandler: (error) => {
    if (error.response) {
      if (!error.response.data) {
        showSnackbar({
          text: error.message,
          color: "error",
        });
      } else {
        switch (error.response.status) {
          case 404:
          case 502:
          case 500: {
            showSnackbar({
              text: error.response.statusText,
              color: "error",
            });
            break;
          }
          default: {
            const _error = error.response.data.message;
            if (typeof _error === "object") {
              Object.keys(_error).forEach((key) => {
                _error[key].forEach((message) => {
                  showSnackbar({
                    text: message,
                    color: "error",
                  });
                });
              });
            } else {
              showSnackbar({
                text: _error,
                color: "error",
              });
            }
          }
        }
      }
    }
  },
};

const Axios = {
  request_GET: (
    url,
    params,
    headers,
    fnSuccessResponse = null,
    fnFailureResponse = null,
    cancel = true,
    // isTokenRequired = true,
    fnFinallyBlock = null,
    responseType = "json"
  ) => {
    if (cancel) {
      source.cancel();
      source = axios.CancelToken.source();
      headers["cancelToken"] = source.token;
    }

    // if (isTokenRequired) {
    // //   const { accessToken } = authToken.getToken();
    //   headers["authorization"] = `Bearer ${accessToken}`;
    // }
    return axios
      .get(url, {
        params: params,
        headers: headers,
        cancelToken: cancel ? source.token : "",
        responseType: responseType,
      })
      .then(function (response) {
        if (fnSuccessResponse != null) {
          fnSuccessResponse(response);
        }
        // AxiosHelper.updatePermissions(response);
      })
      .catch(function (error) {
        if (fnFailureResponse != null) {
          fnFailureResponse(error.response);
        }
        AxiosHelper.errorHandler(error);
      })
      .finally(function () {
        if (fnFinallyBlock != null) {
          fnFinallyBlock();
        }
      });
  },
  request_POST: (
    url,
    data,
    param = null,
    headers = null,
    fnSuccessResponse = null,
    fnFailureResponse = null,
    cancel = true,
    // isTokenRequired = true,
    fnFinallyBlock = null
  ) => {
    var config = {};
    if (cancel) {
      source.cancel();
      source = axios.CancelToken.source();
      config["cancelToken"] = source.token;
    } else {
      source = axios.CancelToken.source();
    }
    // if (isTokenRequired) {
    // //   const { accessToken } = authToken.getToken();
    //   headers["authorization"] = `Bearer ${accessToken}`;
    // }
    if (headers) config["headers"] = headers;
    if (param) config["params"] = param;

    return axios
      .post(url, data, config)
      .then(function (response) {
        if (fnSuccessResponse != null) {
          fnSuccessResponse(response);
        }
      })
      .catch(function (error) {
        if (fnFailureResponse != null) {
          fnFailureResponse(error.response);
        }
        AxiosHelper.errorHandler(error);
      })
      .finally(function () {
        if (fnFinallyBlock != null) {
          fnFinallyBlock();
        }
      });
  },
  request_PATCH: (
    url,
    data,
    param = null,
    headers = null,
    fnSuccessResponse = null,
    fnFailureResponse = null,
    cancel = true,
    // isTokenRequired = true,
    fnFinallyBlock = null
  ) => {
    var config = {};
    if (cancel) {
      source.cancel();
      source = axios.CancelToken.source();
      config["cancelToken"] = source.token;
    } else {
      source = axios.CancelToken.source();
    }

    // if (isTokenRequired) {
    // //   const { accessToken } = authToken.getToken();
    //   headers["authorization"] = `Bearer ${accessToken}`;
    // }
    if (headers) config["headers"] = headers;
    if (param) config["params"] = param;

    return axios
      .patch(url, data, config)
      .then(function (response) {
        if (fnSuccessResponse != null) {
          fnSuccessResponse(response);
        }
      })
      .catch(function (error) {
        if (fnFailureResponse != null) {
          fnFailureResponse(error.response);
        }
        AxiosHelper.errorHandler(error);
      })
      .finally(function () {
        if (fnFinallyBlock != null) {
          fnFinallyBlock();
        }
      });
  },
  request_PUT: (
    url,
    data,
    param = null,
    headers = null,
    fnSuccessResponse = null,
    fnFailureResponse = null,
    cancel = true,
    // isTokenRequired = true,
    fnFinallyBlock = null
  ) => {
    var config = {};
    if (cancel) {
      source.cancel();
      source = axios.CancelToken.source();
      config["cancelToken"] = source.token;
    } else {
      source = axios.CancelToken.source();
    }

    // if (isTokenRequired) {
    // //   const { accessToken } = authToken.getToken();
    //   headers["authorization"] = `Bearer ${accessToken}`;
    // }
    if (headers) config["headers"] = headers;
    if (param) config["params"] = param;

    return axios
      .put(url, data, config)
      .then(function (response) {
        if (fnSuccessResponse != null) {
          fnSuccessResponse(response);
        }
      })
      .catch(function (error) {
        if (fnFailureResponse != null) {
          fnFailureResponse(error.response);
        }
        AxiosHelper.errorHandler(error);
      })
      .finally(function () {
        if (fnFinallyBlock != null) {
          fnFinallyBlock();
        }
      });
  },
  request_DELETE: (
    url,
    param = null,
    headers = null,
    fnSuccessResponse = null,
    fnFailureResponse = null,
    cancel = true,
    // isTokenRequired = true,
    fnFinallyBlock = null
  ) => {
    var config = {};
    if (cancel) {
      source.cancel();
      source = axios.CancelToken.source();
      config["cancelToken"] = source.token;
    } else {
      source = axios.CancelToken.source();
    }

    // if (isTokenRequired) {
    // //   const { accessToken } = authToken.getToken();
    //   headers["authorization"] = `Bearer ${accessToken}`;
    // }
    if (headers) config["headers"] = headers;
    if (param) config["params"] = param;

    return axios
      .delete(url, config)
      .then(function (response) {
        if (fnSuccessResponse != null) {
          fnSuccessResponse(response);
        }
      })
      .catch(function (error) {
        if (fnFailureResponse != null) {
          fnFailureResponse(error.response);
        }
        AxiosHelper.errorHandler(error);
      })
      .finally(function () {
        if (fnFinallyBlock != null) {
          fnFinallyBlock();
        }
      });
  },
};
export default Axios;
